// Base Extended (General styles which are not added 'base styles')
@import 'base-extended';

@import 'dark-layout';

@import 'rtl';

@import 'reactstrap/index';

@import 'app-loader';


.generator-add-item {
  z-index: 999;
  position: relative;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  .generator-icon-plus {
    border-radius: 50%;
    border: 2px solid #877bff;
    cursor: pointer;
    padding: 5px;
    svg {
      background: transparent;
    }
  }
  ul {
    background: #1f2535 !important;
    position: absolute;
    list-style: none;
    bottom: 34px;
    padding: 0;
    border-radius: 4px;

    &.hide {
      display: none;
    }

    &.show {
      display: block;
    }

    li {
      padding: 8px;
      cursor: pointer;

      &.close {
        color: #877bff;
      }
    }
    li:hover {
      color: #000;
      background: #ccc;
    }
  }
}


.delete-item-gen {
  display: flex;
  align-items: center;
  button {
    position: relative;
    top: 5px;
    height: 37px;
    padding: 0 8px;
  }
}